


































import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import axios, { CancelTokenSource } from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify'
import Confirmacao from '../ui/Confirmacao.vue'
import { Relatorio } from '@/models/views/ViewRelatorio'
import { FindRelatorioUseCase } from '@/usecases'
import { downloadFilesFromS3 } from '@/shareds/s3/files'

@Component({
	components: {
		Confirmacao,
	},
})
export default class ListagemDeRelatorioPorUsuario extends Vue {
	@Prop({ type: String }) relatorioId!: string

	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	statusColor = statusColor

	findUseCase = new FindRelatorioUseCase()
	cancelToken: CancelTokenSource | null = null
	relatorios: Relatorio[] = []
	cancelando: string[] = []

	carregando = false
	baixando = false

	totalRegistros = -1

	headersRelatorios = [
		{ text: 'Nome do Arquivo', value: 'nomeRelatorio', sorteable: false },
		{ text: 'Gerado Em', value: 'geradoEm' },
		{ text: 'Ações', value: 'actions', width: 'auto', sorteable: false, align: 'center' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 5,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	get listaDeRelatoriosFormatada() {
		return this.relatorios.map(relatorio => {
			return {
				...relatorio,
				geradoEm: dateTimeToPtBrFormat(relatorio.geradoEm),
			}
		})
	}

	@Watch('paginacao')
	async buscar () {
		if(!this.relatorioId) return
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const pagina = await this.findUseCase.listarRelatorios(this.relatorioId, params, axiosConfig)
			this.relatorios = pagina.content
			this.totalRegistros = pagina.totalElements
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('relatorioId')
	onChangeRelatorioId(relatorioIdAnterior: string, relatorioIdAtual: string) {
		if (relatorioIdAnterior !== relatorioIdAtual) {
			this.buscar()
		}
	}

	baixarRelatorio(relatorioSelecionado) {
		try {
			this.baixando = true
			const nomeDoArquivo = decodeURIComponent(relatorioSelecionado.urlArquivo.substring(relatorioSelecionado.urlArquivo.lastIndexOf('/') + 1))
			const caminhoDaPasta = `relatorios/${relatorioSelecionado.usuarioId}`
			downloadFilesFromS3(caminhoDaPasta, nomeDoArquivo)
			
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.baixando = false
		}
	}
}
export function statusColor(status: string) {
	if(status === 'Concluido') return 'success--text font-weight-bold'
	if(status === 'Pendente') return 'warning--text font-weight-bold'
	if(status === 'Cancelado') return 'error--text font-weight-bold'
}
