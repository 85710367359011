








































import { Vue, Component, PropSync } from 'vue-property-decorator'
import { Loja } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeBuscaDeLoja from '@/components/loja/DialogoDeBuscaDeLoja.vue'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'

@Component({
	components: {
		DialogoDeBuscaDeLoja,
	},
})
export default class ListaDeLojas extends Vue  {
	@PropSync('value') lojas!: Loja[]

	UsuariosModule = UsuariosModule

	remover(indice: number) {
		this.lojas.splice(indice, 1)
	}

	inserirLojaDoUsuario(loja: Loja) {
		const jaEstaNaInclusa = this.lojas.some(
			({ id }) => loja.id === id,
		)
		if (jaEstaNaInclusa) {
			AlertModule.setError(`${loja.nome} já consta na lista de lojas`)
			return
		}
		this.$emit('input', [
			...this.lojas,
			loja,
		])
	}
}

